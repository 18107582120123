<template>
    <tabbar-page>
        <div class="logo-box">
            <img class="logo-img" :src="logoImg" />
            <h1 class="logo-name">广告不求人</h1>
            <h2 class="logo-slogan">一站式广告发布平台</h2>
        </div>
        <h2 class="block-title">{{ $t("网站") }}</h2>
        <div>
            <div :key="itm.id" v-for="itm in sites" class="ad-card-box">
                <van-card :title="itm.name" :desc="itm.summary" class="card-item" @click="onSiteClick(itm)">
                    <template #thumb>
                        <van-image fit="fill" :src="itm.logoUrl" />
                    </template>
                    <template #tags>
                        <van-tag :key="spec.id" v-for="spec in itm.specs" plain class="spec-tag" type="danger">{{ spec.name
                        }}</van-tag>
                    </template>
                </van-card>
            </div>
        </div>
        <h2 class="block-title">{{ $t("套餐") }}</h2>
        <div>
            <div :key="itm.id" v-for="itm in combos" class="ad-card-box">
                <van-card :title="itm.name" :desc="itm.summary" class="card-item" @click="onComboClick(itm)">
                    <template #thumb>
                        <van-image fit="fill" :src="itm.logoUrl" />
                    </template>
                    <template #tags>
                        <van-tag plain class="spec-tag" type="danger">{{ itm.price | priceToDisplay }}欧</van-tag>
                    </template>
                </van-card>
                <van-cell :key="site.site.id" v-for="site in itm.sites" :title="site.site.name" :value="site.spec.name" />
            </div>
        </div>
        <van-divider>{{ $t("客服微信") }}</van-divider>
        <van-row>
            <van-col span="12">
                <div class="kfwx-box">
                    <div class="name">{{ $t("客服1") }}</div>
                    <van-image fit="fill" src="imgs/wx_1.jpg" />
                </div>
            </van-col>
            <van-col span="12">
                <div class="kfwx-box">
                    <div class="name">{{ $t("客服2") }}</div>
                    <van-image fit="fill" src="imgs/wx_2.jpg" />
                </div>
            </van-col>
        </van-row>
    </tabbar-page>
</template>

<script>
import { Card, Image as VanImage, Tag, CellGroup, Cell, Col, Row, Divider } from "vant";
import TabbarPage from "../../components/tabbar-page";
import logoImg from "common/assets/imgs/logo.png";

export default {
    components: {
        [TabbarPage.name]: TabbarPage,
        [VanImage.name]: VanImage,
        [Card.name]: Card,
        [Tag.name]: Tag,
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Col.name]: Col,
        [Row.name]: Row,
        [Divider.name]: Divider,
    },

    data() {
        return {
            logoImg: logoImg,
            sites: [],
            combos: [],
        };
    },
    mounted() {
        this.onLoadSites();
        this.onLoadCombos();
    },
    methods: {
        async onLoadSites() {
            let res = await this.$userApi.site.getSites();
            if (res.status == 200) this.sites = res.data.items;
        },
        async onLoadCombos() {
            let res = await this.$userApi.site.getSiteCombos();
            if (res.status == 200) this.combos = res.data.items;
        },
        onSiteClick(item) {
            if (!this.checkLogin()) return;
            this.$router.push({
                path: "reserve/pub",
                query: {
                    type: 1,
                    typeId: item.id,
                },
            });
        },
        onComboClick(item) {
            if (!this.checkLogin()) return;
            this.$router.push({
                path: "reserve/pub",
                query: {
                    type: 2,
                    typeId: item.id,
                },
            });
        },
        checkLogin() {
            if (!this.$$isLogined) {
                this.$router.push("login");
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .logo-box {
    padding: 0 1rem 0 1rem;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    text-align: center;

    .logo-img {
        display: inline-block;
        width: 2rem;
        margin-right: 0.5rem;
        position: relative;
        top: 0.5rem;
    }

    .logo-name {
        font-size: 1.5rem;
        display: inline-block;
        color: #39a9ed;
        text-align: center;
        margin: 0;
    }

    .logo-slogan {
        font-size: 0.8rem;
        color: #455a6499;
        margin: 0.5rem 0 0.5rem 0;
        font-weight: normal;
    }
}

.kfwx-box {
    width: 100%;
    padding: .5rem;
    box-sizing: border-box;

    .name {
        text-align: center;
    }

    .van-image__img {
        width: 100%;
    }
}
</style>